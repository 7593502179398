import { Component } from 'react';

import { isReachRedirect, isRedirect } from '@xing-com/crate-core-router';

import { reportError } from '../report-error';

export class ErrorBoundary extends Component<
  React.PropsWithChildren<{
    onError: (error: Error) => React.ReactElement;
    context?: Record<string, unknown>;
  }>,
  { error: Error }
> {
  componentDidCatch(
    error: Error,
    { componentStack, digest }: React.ErrorInfo
  ): void {
    if (isReachRedirect(error) || isRedirect(error)) {
      throw error;
    }

    this.setState({ error });

    reportError(error, {
      componentStack: componentStack ?? undefined,
      digest: digest ?? undefined,
      ...this.props.context,
    });
  }

  render(): React.ReactNode {
    const { children, onError } = this.props;
    const { error } = this.state ?? {};

    return error ? onError(error) : children;
  }
}
